<template>
  <div>
    <base-page
      :title="$t('documentManage.title')"
      :formData="formData"
      :config="config"
      @functionMethod="functionMethod"
      @search="search"
    >
      <table-list
        ref="tableList"
        :url="'/' + $util.getLocalStorage('apiprefix') + $api.findDocument"
        :columns="columns"
        :defaultParams="defaultParams"
        v-model="tableRow"
      >
      <!-- 上传时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
      </template>
      <!-- 操作 -->
      <template v-slot:option="{ row }">
         <!-- 上架 下架 删除 -->
          <!-- lang:待使用 -->
         <template v-if="row.status === 'TO_BE_USED'">
            <!-- lang:上架 -->
            <el-button type="text" @click="documentPutAway(row)">
            {{ $t('functionEnum.DOCUMENT_IN_USE') }}
            </el-button>
            <!-- lang:下载 -->
            <el-button type="text" @click="documentDownload(row)">
            {{ $t('functionEnum.DOCUMENT_DOWNLOAD') }}
            </el-button>
             <!-- lang:删除 -->
            <el-button type="text" @click="documentDelete(row)">
            {{ $t('functionEnum.DOCUMENT_DELETE') }}
            </el-button>
         </template>
        <!-- lang:使用中 -->
         <template v-if="row.status === 'IN_USE'">
            <!-- lang:下架 -->
            <el-button type="text" @click="documentSoldout(row)">
            {{ $t('functionEnum.DOCUMENT_SOLD_OUT') }}
            </el-button>
         </template>
        <!-- lang:已下架 -->
         <template v-if="row.status === 'SOLD_OUT'">
            <!-- lang:下载 -->
            <el-button type="text" @click="documentDownload(row)">
            {{ $t('functionEnum.DOCUMENT_DOWNLOAD') }}
            </el-button>
           <!-- lang:删除 -->
           <el-button type="text" @click="documentDelete(row)">
             {{ $t('functionEnum.DOCUMENT_DELETE') }}
           </el-button>
         </template>
      </template>

      </table-list>
      <detail v-if="showDetail" :tableRow="tableRow" @closePage="closePage" />
      <detail-tmva v-if="showDetailTmva" :tableRow="tableRow" @closePage="closePage" />
    </base-page>
  </div>
</template>

<script>
import Detail from './detail'
import DetailTmva from './detailTmva'
export default {
  components: {
    Detail,
    DetailTmva
  },
  data() {
    const nowTime = new Date().getTime()
    const prevTime = nowTime - 365 * 24 * 60 * 60 * 1000
    const startCreateTime = this.$util.format(prevTime, 'yyyy-MM-dd')
    const endCreateTime = this.$util.format(nowTime, 'yyyy-MM-dd')
    return {
      defaultParams: {
        // startCreateTime: new Date(startCreateTime + ' 00:00:00').getTime(),
        // endCreateTime: new Date(endCreateTime + ' 23:59:59').getTime(),
      },
      // 搜索
      formData: {
        createTime: [startCreateTime, endCreateTime],
      },
      config: [
        // lang:开始时间和结束时间
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 8,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ],
      // 表格页
      tableRow: null,
      contextFromObj: this.$util.listToObj(this.$dict.contextFrom()),
      yesOrNoObj: this.$util.listToObj(this.$dict.yesOrNo()),
      columns: [
        // lang:上传时间
        {
          label: this.$t('documentManage.uploadTime'),
          prop: 'createTime',
        },
        // lang:文档类型
        {
          label: this.$t('documentManage.fileType'),
          prop: 'dictId',
          callback: (row) => {
            return row.type ? this.$util.getTreeNames(row.type, 'value') :  ''
          },
        },
        // lang:文档版本号
        {
          label: this.$t('documentManage.fileVersion'),
          prop: 'version',
          callback: (row) => {
            return 'V'+ row.version + '.0'
          },
        },
        // lang:客户端
        {
          label: this.$t('documentManage.deviceType'),
          prop: 'deviceType',
          hidden: true,
          callback: (row) => {
            if(row.deviceType === 'PC') return 'PC端'
            if(row.deviceType === 'WEB') return 'WEB端'
            if(row.deviceType === 'ANDROID') return '手机端'
            if(row.deviceType === 'ANDROID_AR') return 'AR端'
            if(row.deviceType === 'WEB_ADMIN') return '管理端'
            if(row.deviceType === 'UNKNOWN') return '未知'
          },
        },
        // lang:软件版本号
        {
          label: this.$t('documentManage.softwareVersionId'),
          prop: 'softwareVersion',
          hidden: true,
          callback(row) {
            return row && row.softwareVersion && row.softwareVersion.title
          }
        },
        // lang:状态
        {
          label: this.$t('documentManage.status'),
          prop: 'status',
          callback: (row) => {
            if(row.status === 'TO_BE_USED') return '待使用'
            if(row.status === 'IN_USE') return '使用中'
            if(row.status === 'SOLD_OUT') return '已下架'
          },
        },
        // lang:操作
        {
          label: this.$t('base.option'),
          prop: 'option',
        },
      ],
      // 详情页
      showDetail: false,
      showDetailTmva :false
    }
  },
  mounted() {
    this.apiFindTree()
  },
  methods: {
    // 查询api
    apiFindTree() {
      if(this.$util.getLocalStorage('apiprefix') === 'core') {
        this.columns.splice(3,2)
      }
    },
    // 关闭详情页回调
    closePage() {
      this.showDetail = false
      this.showDetailTmva = false
      this.search()
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      const formData = this.$util.copyData(this.formData)
      if (formData.api) formData.api = formData.api.pop()
      this.defaultParams = {}
      this.$refs.tableList.searchList(formData, isActiveQuery)
    },
    // 权限按钮点击回掉
    functionMethod(item) {
      const { method } = item
      if (method === 'del' && !this.tableRow) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      const tableRow = this.$util.copyData(this.tableRow)
      console.log(tableRow)
      console.log(method)
      switch (method) {
        case 'add':
          this.showDetail = true
          break
        case 'addTmva':
          this.showDetailTmva = true
          break
        case 'del':
           this.documentDelete(tableRow)
          break
        default: // do something
      }
    },
    // 上架
    documentPutAway(item) {
      this.$element
        .showConfirm({
          content: "同类型文档只能上架最新",
        })
        .then(() => {
          this.$ajax({
            url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.putAwayDocument,
            data:{
              id:item.id
            },
          }).then(() => {
            // lang:删除成功或禁用成功或启用成功或重置成功
            this.$element.showMsg('上架成功', 'success')
            this.search()
          })
        })
    },
     // 下架
    documentSoldout(item) {
      this.$element
        .showConfirm({
          content: "是否确认下架",
        })
        .then(() => {
          this.$ajax({
            url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.soldOutDocument,
            data:{
              id:item.id
            },
          }).then(() => {
            // lang:删除成功或禁用成功或启用成功或重置成功
            this.$element.showMsg('下架成功', 'success')
            this.search()
          })
        })
    },
    // 下载
    documentDownload(item) {
      const { fileEntity } = item
      const { originalName, objectName }  = fileEntity
      this.$ajax({
          url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.getAliyunOssSTS,
        }).then((res) => {
          const OSS = require('ali-oss')
          const client = new OSS({
            region: res.region,
            accessKeyId: res.accessKeyId,
            accessKeySecret: res.accessKeySecret,
            stsToken: res.securityToken,
            bucket: res.bucketName,
          })
          const filename = originalName
          const response = {
            'content-disposition': `attachment; filename=${encodeURIComponent(filename)}`
          }
          const urlDowm = client.signatureUrl(objectName, { response })
          const a = document.createElement('a')
          // a.target = '_blank'
          a.href = urlDowm
          a.click()
        })
    },
    // 删除
    documentDelete(item) {
      this.$element
        .showConfirm({
          content: "是否确认删除",
        })
        .then(() => {
          this.$ajax({
            url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.deleteDocument,
            data:{
              id:item.id
            },
          }).then(() => {
            // lang:删除成功或禁用成功或启用成功或重置成功
            this.$element.showMsg('删除成功', 'success')
            this.search()
          })
        })

    },
  },
}
</script>
